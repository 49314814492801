import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9ofaaro', 'template_896yoaq', form.current, '775Ik_LkyqLCHhIRV')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className='join' id='join'>
      <div className="left-j">
        <hr />
        <div>
            <span className='storke-text'>READY TO</span>
            <span> LEVEL UP</span>
            </div>
       
        <div>
            <span>YOUR BODY</span>
            <span className='storke-text'>  WITH US?</span>
            </div>
      </div>
      <div className="right-j">
        <form ref={form} action="" className="email-container " onSubmit={sendEmail}>
        <input type="email" name='user-email' placeholder='EnterYour Email Here' />
        <button className="btn btn-j">Join Us </button>
        </form>
      </div>
    </div>
  )
}

export default Join
