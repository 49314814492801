import React from 'react'
import './program.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Program = () => {
  return (
    <div className='Programs' id='programs'>
        {/* header */}
        <div className="program-header">
            <span className='storke-text'>Explore Our</span>
            <span>Programs</span>
            <span className='storke-text'>To Shape You</span>
        </div>
      <div className="program-categories">
        {programsData.map((program)=>(
            <div className="category">
                {program.image}
                <spaan>{program.heading}</spaan>
                <spaan>{program.details}</spaan>
                <div className="join-now"><span>Jion Now</span><img src={RightArrow} alt="" /></div>
            </div>
        ))}
      </div>

    </div>
  )
}

export default Program
