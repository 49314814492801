import React from 'react'
import './Footer.css'
import Insta from '../../assets/instagram.png'
import Github from '../../assets/github.png'
import LinkedIn from '../../assets/linkedin.png'
import Logo from '../../assets/logo.png'



const Footer = () => {
  return (
    <div className='footer-container'>
      <hr />
      <div className="footer">
        <div className="links">
        <img src={Github} alt="" />
        <img src={Insta} alt="" />
        <img src={LinkedIn} alt="" />
      </div>
      <div className="logo-f">
        <img src={Logo} alt="" />
      </div>
      </div>
      <div className="blur footer-blur-1"></div>
      <div className="blur footer-blur-2"></div>
    </div>
  )
}

export default Footer
