
import React from 'react'
import './Reasons.css'
import Image1 from '../../assets/image1.png'
import Image2 from '../../assets/image2.png'
import Image3 from '../../assets/image3.png'
import Image4 from '../../assets/image4.png'
import tick from '../../assets/tick.png'
import addidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import nd from '../../assets/nb.png'

const Reasons = () => {
  return (
    <div className='Reasons' id='reasons'>
      <div className="left-r">
        <img src={Image1} alt="" />
        <img src={Image2} alt="" />
        <img src={Image3} alt="" />
        <img src={Image4} alt="" />
      </div>
      <div className="right-r">
        <span>Some Reasons</span>
        <div>
            <span className='storke-text'>why</span>
            <span> choose us?</span>
            </div>

        <div className='details-r'>
           <div>
            <img src={tick} alt="" />
            <span>over 140+ expert coachs</span>
           </div>
           <div>
            <img src={tick} alt="" />
            <span>train smarter and faster then </span>
           </div>
           <div>
            <img src={tick} alt="" />
            <span>1 free program for new member</span>
           </div>
           <div>
            <img src={tick} alt="" />
            <span>reliable partners</span>
           </div>

        </div>
        <span style={{
            color:'var(--orange)',
            fontWeight:"bold"
        }}>
            our partners
        </span>
        <div className="partners">
            <img src={nd} alt="" />
            <img src={addidas} alt="" />
            <img src={nike} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Reasons
